@import "~@flaticon/flaticon-uicons/css/all/all";

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
a {
  color: #000;
}

@font-face {
  font-family: "Tapestry";
  src: url(./font/Tapestry.ttf);
}
@font-face {
  font-family: "Rufina";
  src: url(./font/Rufina.ttf);
}
@font-face {
  font-family: "Neuton";
  src: url(./font/Neuton.ttf);
}
@font-face {
  font-family: "Poppins";
  src: url(./font/Poppins.ttf);
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "iconfont"; /* Project id 3822463 */
  src: url("https://at.alicdn.com/t/c/font_3822463_moh0tdcub8k.woff2?t=1671002553449")
      format("woff2"),
    url("https://at.alicdn.com/t/c/font_3822463_moh0tdcub8k.woff?t=1671002553449")
      format("woff"),
    url("https://at.alicdn.com/t/c/font_3822463_moh0tdcub8k.ttf?t=1671002553449")
      format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  line-height: 1;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  width: 100%;
}
.App {
  width: 100%;
}
/* .ant-carousel{
	width: 100%;
	height: 100% !important;
}
.slick-slider{
	height: 100% !important;
}
.slick-list{
	height: 100% !important;
} */
.slick-track {
  height: calc(100% - 2px);
}
.header {
  width: 100%;
  /* height: 1.74rem; */
  background: #f8f2ff;
  /* display: flex;
  justify-content: space-between; */
}
.headers {
  height: 111px;
  width: 100%;
}
.header-box-show {
  width: 100%;
  background: #f8f2ff;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 8;
}
.headerNav {
  font-family: "Tapestry";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  /* width: 28%; */
  min-width: 580px;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 80px;
}
.navBox {
  padding: 58px 0 0;
  /* font-style: italic; */
  letter-spacing: 8px;
  cursor: pointer;
}
.navBorder {
  height: 2px;
  width: 80%;
  background: #000;
  margin: 32px auto 0;
}
.headerIcon {
  /* width: 20%; */
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
}
.iconBox {
  width: 64px;
  height: 64px;
  min-width: 64px;
  background: #ffffff;
  border-radius: 25px;
  margin-top: 26px;
  text-align: center;
  margin-right: 26px;
  color: #000;
  line-height: 72px;
  font-size: 28px;
  
  a {
    color: inherit;
  }
}
.Icons {
  display: block;
  margin: 0 auto;
}
.Icon {
  display: none;
  margin: 0 auto;
}
.iconBox:hover {
  width: 64px;
  height: 64px;
  background: #000000;
  border-radius: 25px;
  margin-top: 26px;
  color: #fff;
  text-align: center;
  // line-height: 64px;
  font-size: 28px;
}
.iconBox:hover .Icon {
  display: block;
}
.iconBox:hover .Icons {
  display: none;
}
.iconBox:nth-child(4) img {
  width: 25px;
}
.content-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content {
  width: 100%;
  overflow: hidden;
  /* height: calc(100vh - 111px); */
  position: relative;
}
.contentOne {
  width: 100%;
  /* height: calc(100vh - 111px); */
  background: #fff;
  position: relative;
  overflow: hidden;
}
.contentOneTop {
  height: 174px;
  width: 100%;
}
.contentOneMain {
  /* height: calc(100vh - 111px); */
  /* height: 9.1rem; */
  background: linear-gradient(
    100.16deg,
    #bb64e4 0.77%,
    #6e42a7 39.73%,
    #381368 96.67%
  );
  position: relative;
  display: flex;
  align-items: center;
}
.contentSsr {
  width: 58%;
  position: absolute;
  top: -12%;
  left: 0;
  z-index: 20;
}
.contentAdora {
  width: 20%;
  margin-top: 10%;
}
.contentAdoraStroke {
  right: 20px;
  width: 48%;
  position: absolute;
}
.contentUnion {
  position: absolute;
  right: 11%;
  top: 22px;
  width: 30%;
}
.dowloadWay {
  width: 34%;

  position: absolute;
  right: 7.8%;
  bottom: 10%;
}
.dowloadWays {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dowloadWayImg {
  width: 28%;
}
.contentThree {
  /* height: 1080px; */
  background: url(./image/twoBg.png) no-repeat center / cover;
  overflow: hidden;
  position: relative;
  height: 100%;
  min-width: 1300px;
}

.TwoTitle {
  display: block;
  margin: 5% auto 5%;
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.ThreeTitle {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 69px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  background: linear-gradient(180deg, #db4573 16.67%, #ffaec7 83.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.VectorLine {
  height: 12px;
  margin-top: 30px;
}
.transverse {
  /* position: absolute;
	bottom: 180px;
	left: 0; */
  /* margin-bottom: 10%; */
  margin: 6% auto 10%;
  width: 100%;
  z-index: 10;
}
.contentTwo {
  width: 100%;
  /* height: 1080px; */
  /* height: 1080px; */
  position: relative;
  margin-top: -2px;
}
.contentTwoBg {
  height: 100%;
  width: 60%;
  background: #14120e;
  float: right;
  border-bottom-left-radius: 50px;
}
.popup-video {
  width: 100vw;
  height: 100vh;
  background: #14120e;
  position: fixed;
  left: 0;
  top: 0;
  visibility: visible;
  z-index: 9999;
  display: flex;
  align-items: center;
  min-width: 1300px;
}
.closeVideo {
  position: absolute;
  top: 10%;
  right: 5%;
  width: 60px;
  height: 60px;
  background: url(./image/close.svg);
}

.video {
  height: 80%;
  width: 80%;
  display: block;
  margin: 0 auto;
}
.contentTwoBgMain {
  height: 100%;
  width: 100%;
  /* background: url(./image/Groupbg.png); */
  border-bottom-left-radius: 50px;
  position: relative;
}
.contentTwoMain {
  /* position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; */
  height: 100%;
  overflow: hidden;
}
