.home-box {
  /* background-image: url('../../images/timg.jpg'); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* background-position: center; */
  background-position: 0 4vh;
  /* min-height: 100vh; */
  /* height: 100%; */
}

.home-box .carousel {
  position: relative;
  height: 100%;
  min-height: 240px;
}

.home-box .carousels {
  position: relative;
  /* height: 400px; */
  /* height: 1080px; */
  height: 100%;
}

.home-box .carousel .slide {
  position: absolute;
  height: 100%;
  -webkit-transition-timing-function: ease-out;
  /*之前是ease-out*/
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.home-box .carousels .slide {
  position: absolute;
  height: 100%;
  -webkit-transition-timing-function: ease-in-out;
  /*之前是ease-out*/
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  margin: 0 auto;
}

.img-box {
  /* height: 400px; */
  float: left;
  margin-left: 105px;
  position: relative;
  height: 100%;
  min-height: 375px;
  min-width: 450px;
}

.img-boxs {
  height: 100%;
  float: left;
  position: relative;
  min-width: 1300px;
}

.img-boxs img {
  width: 100%;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
  min-width: 1300px;
}

.img-box img {
  width: 100%;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
}

.img-content {
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  height: 100%;
  padding: 35px;
  border-radius: 20px;
  position: relative;
}

.content-title {
  font-family: "Neuton";
  font-size: 18px;
}

.content-title-big {
  font-family: "Neuton";
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 5px;
  padding-top: 4px;
}

.content-text {
  font-family: "Neuton";
  padding-top: 20px;
  letter-spacing: 0.5px;
}

.content-time {
  font-family: "Neuton";
  font-size: 36px;
  position: absolute;
  left: 34px;
  bottom: 10%;
}

.box-title {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 79px;
  /* text-transform: capitalize;
	background: linear-gradient(180deg, #E6C273 24.07%, #FFEEC8 90.12%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text; */
  position: absolute;
  top: 19%;
  left: 54%;
  /* text-fill-color: transparent; */
  color: #fff6e4;
}

.box-title-s {
  font-family: "Rufina";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  /* color: #FFF6E4; */
}

.box-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  text-transform: capitalize;
  color: rgba(255, 246, 228, 0.6);
  position: absolute;
  width: 597px;
  height: 293px;
  left: 50%;
  top: 38%;
  z-index: 1;
}

.box-SSR {
  position: absolute;
  left: 0;
  font-size: 32px;
  bottom: -145px;
  text-transform: capitalize;
  font-family: "Rufina";
  background: linear-gradient(
    117.97deg,
    #f1e5bb 41.42%,
    #ebdbb3 54.27%,
    #fef4dd 63.06%,
    #f1d8a1 72.67%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.box-shadow {
  position: absolute;
  left: 0;
  font-size: 32px;
  bottom: -200px;
  text-transform: capitalize;
  font-family: "Rufina";
  background: linear-gradient(
    117.97deg,
    #f1e5bb 41.42%,
    #ebdbb3 54.27%,
    #fef4dd 63.06%,
    #f1d8a1 72.67%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.clothingSwiper {
  position: absolute;
  top: 0px;
  left: 0;
  /* width: 15px;
	height: 15px;
	background: #000; */
}
.positionSwiper {
  position: absolute;
  bottom: 110px;
  left: 0;
  /* width: 15px;
	height: 15px;
	background: #000; */
}
.box-video {
  color: #ff0000;
  font-size: 30px;
  width: 94px;
  height: 94px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 60px auto 0;
}
.box-video > i {
  display: block;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  line-height: 74px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

.box-text-title {
  text-align: right;
  width: 100%;
  margin-top: 30px;
}

.left-control,
.right-control {
  position: absolute;
  width: 110px;
  height: 110px;
  top: 45%;
  font-size: 40px;
  background: #000;
  display: flex;
  cursor: pointer;
  color: #fff;
  opacity: 0;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  z-index: 5;
}

.left-controls,
.right-controls {
  position: absolute;
  width: 110px;
  height: 110px;
  top: 45.5%;
  font-size: 40px;
  background: #fff;
  display: flex;
  cursor: pointer;
  color: #000;
  opacity: 0;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  z-index: 5;
}

.left-controls:hover {
  color: #fff;
  background: #000;
}

.right-controls:hover {
  color: #fff;
  background: #000;
}

.left-control:hover {
  color: #000;
  background: #fff;
}

.right-control:hover {
  color: #000;
  background: #fff;
}

.left-controls > span {
  display: block;
  margin: 0 auto;
}

.right-controls > span {
  display: block;
  margin: 0 auto;
}

.left-controls {
  left: 5%;
}

.right-controls {
  right: 5%;
}

.left-control > span {
  display: block;
  margin: 0 auto;
}

.right-control > span {
  display: block;
  margin: 0 auto;
}

.left-control {
  left: 5%;
}

.right-control {
  right: 5%;
}

.carousel:hover .left-control,
.carousel:hover .right-control {
  opacity: 1;
}

.carousels:hover .left-controls,
.carousels:hover .right-controls {
  opacity: 1;
}

li {
  list-style-type: none !important;
  position: relative;
}

.SwiperNum {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.SwiperNum img {
  width: 20px;
}
@media screen and (max-width: 1800px) {
  .box-title {
    font-size: 52px;
    top: 19%;
  }
  .box-title-s {
    font-size: 28px;
  }
  .box-text {
    width: 560px;
    font-size: 14px;
  }
  .box-SSR {
    bottom: -80px;
    font-size: 28px;
  }
  .box-shadow {
    bottom: -110px;
    font-size: 28px;
  }
  .box-video {
    width: 84px;
    height: 84px;
    bottom: -120px;
  }
  .img-content {
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    height: 100%;
    padding: 26px 28px;
    border-radius: 20px;
    position: relative;
  }

  .content-title {
    font-family: "Neuton";
    font-size: 15px;
  }

  .content-title-big {
    font-family: "Neuton";
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 4px;
    margin-top: -0px;
    margin-left: 4px;
  }

  .content-time {
    font-family: "Neuton";
    font-size: 26px;
    position: absolute;
    left: 28px;
    bottom: 44px;
  }
}
@media screen and (max-width: 1600px) {
  .box-title {
    font-size: 52px;
    top: 19%;
  }
  .box-title-s {
    font-size: 28px;
  }
  .box-text {
    width: 560px;
    font-size: 14px;
  }
  .box-SSR {
    bottom: -80px;
    font-size: 28px;
  }
  .box-shadow {
    bottom: -110px;
    font-size: 28px;
  }
  .img-content {
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    height: 100%;
    padding: 26px 28px;
    border-radius: 20px;
    position: relative;
  }
  .box-video {
    width: 74px;
    height: 74px;
  }
  .box-video > i {
    width: 62px;
    height: 62px;
    line-height: 62px;
    font-size: 28px;
  }
  .content-title {
    font-family: "Neuton";
    font-size: 15px;
  }

  .content-title-big {
    font-family: "Neuton";
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 4px;
    margin-top: -0px;
    margin-left: 4px;
  }

  .content-time {
    font-family: "Neuton";
    font-size: 26px;
    position: absolute;
    left: 28px;
    bottom: 44px;
  }
}

/* @media screen and (max-width:1400px) {
	.img-content {
		box-sizing: border-box;
		width: 100%;
		cursor: pointer;
		height: 100%;
		padding: 22px 24px;
		border-radius: 20px;
		position: relative;
	}

	.content-text {
		font-family: 'Neuton';
		padding-top: 6px;
		font-size: 14px;
	}

	.content-title {
		font-family: 'Neuton';
		font-size: 14px;
	}

	.content-title-big {
		font-family: 'Neuton';
		font-size: 30px;
		font-weight: bold;
		letter-spacing: 2.5px;
		margin-top: -0px;
		margin-left: 2px;
	}

	.content-time {
		font-family: 'Neuton';
		font-size: 24px;
		position: absolute;
		left: 26px;
		bottom: 36px;
	}
} */

@media screen and (max-width: 1500px) {
  .box-title {
    font-size: 40px;
    top: 19%;
  }
  .box-title-s {
    font-size: 22px;
    line-height: 32px;
  }
  .box-text {
    width: 480px;
    font-size: 12px;
  }
  .box-SSR {
    bottom: -30px;
    font-size: 22px;
  }
  .box-shadow {
    bottom: -60px;
    font-size: 22px;
  }
  .box-video {
    width: 64px;
    height: 64px;
  }
  .box-video > i {
    width: 52px;
    height: 52px;
    line-height: 52px;
    font-size: 26px;
  }
  .left-controls,
  .right-controls {
    font-size: 30px;
    width: 80px;
    height: 80px;
  }

  .left-control,
  .right-control {
    font-size: 30px;
    width: 80px;
    height: 80px;
  }

  .SwiperNum img {
    width: 15px;
  }

  .img-content {
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    height: 100%;
    padding: 26px;
    border-radius: 20px;
    position: relative;
  }

  /* .content-title {
		font-family: 'Neuton';
		font-size: 12px;
	}

	.content-title-big {
		font-family: 'Neuton';
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 1.5px;
		margin-top: -8px;
	}

	.content-text {
		font-family: 'Neuton';
		padding-top: 6px;
		font-size: 12px;
		
	}

	.content-time {
		font-family: 'Neuton';
		font-size: 22px;
		position: absolute;
		left: 20px;
		bottom: 26px;
	} */
}

/* @media screen and (max-width:1000px) {
	.box-title{
		font-size: 30px;
		top: 19%;
		left: 58%;
		min-width: 300px;
	}
	.box-title-s{
		font-size: 16px;
		line-height: 32px;
		min-width: 300px;
	}
	.box-text{
		width: 340px;
		font-size: 12px;
	}
	.box-SSR{
		bottom: -120px;
		font-size: 18px;
	}
	.box-shadow{
		bottom: -150px;
		font-size: 18px;
	}
	.box-video{
		width: 74px;
		height: 74px;
	}
	.box-video>i{
		width: 62px;
		height: 62px;
		line-height: 62px;
		font-size: 28px;
	}
	
	.left-controls,
	.right-controls {
		font-size: 20px;
		width: 60px;
		height: 60px;
	}

	.left-control,
	.right-control {
		font-size: 20px;
		width: 60px;
		height: 60px;
	}

	.SwiperNum img {
		width: 10px;
	}
} */
