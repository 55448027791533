/* @font-face {
	font-family: 'Montserrat';
	src: url(../font/MontserratAlternates-Regular.ttf);
} */
.footer {
  /* height: 450px; */
  background: #000;
  width: 100%;
  color: #fff;
  /* font-family: 'Montserrat'; */
  font-style: italic;
  padding: 70px;
  box-sizing: border-box;
}
.footerTop {
  display: flex;
  justify-content: space-between;
}
.footerTop > img {
  display: none;
}
.footerTopLeft {
  display: flex;
  justify-content: space-between;
  width: 350px;
}
.footerTopLeftTitle {
  font-size: 20px;
  cursor: pointer;
}
.footerMin {
  margin: 30px 0;
}
.footerMinLeft {
  display: flex;
  /* justify-content: space-between; */
  /* width: 40%; */
}
.footerMinLeftTitle {
  font-size: 12px;
  cursor: pointer;
  margin-right: 20px;
  color: #fff;
}
.footerBottom {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  font-size: 16px;
}
.footerBottom > div + div {
  margin-left: 20px;
}
.footer .footerTopLeftTitle {
  color: #fff;
}
